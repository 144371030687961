import { createRouter, createWebHistory } from 'vue-router'
import PageSignin from '../pages/PageSignin.vue'
import store from '../store/index.js'

const routes = [
    {
        //ログイン
        path: '/signin',
        name: 'Signin',
        meta: { for_guest: true, title: 'ログイン' },
        component: PageSignin
    },
    {
        //ダッシュボード
        path: '/',
        name: 'Home',
        meta: { title: 'ダッシュボード' },
        component: () => import('../pages/PageHome.vue')
    },
    {
        // A21 [案件]案件一覧
        path: '/project/',
        name: 'Project',
        meta: { title: '案件リスト' },
        component: () => import('../pages/project/PageProject.vue')
    },
    {
        // A22 [案件]案件詳細
        path: '/project/:id',
        name: 'ProjectEdit',
        component: () => import('../pages/project/PageProjectEdit.vue')
    },
    {
        // A23 [案件]案件新規登録
        path: '/project/add',
        name: 'ProjectAdd',
        meta: { gate: 'canEditProject', title: '案件新規登録' },
        component: () => import('../pages/project/PageProjectAdd.vue')
    },
    {
        // A24 [案件]リスト管理
        path: '/condition',
        name: 'Condition',
        meta: { title: 'リスト管理' },
        component: () => import('../pages/condition/PageCondition.vue')
    },
    {
        // A25 [案件]CSV一括登録
        path: '/project/import',
        name: 'ProjectImport',
        meta: { gate: 'canEditProject', title: 'CSV一括登録' },
        component: () => import('../pages/project/PageProjectImport.vue')
    },
    {
        // A26 [案件]案件一括変更
        path: '/project/bulk',
        name: 'ProjectBulk',
        meta: { gate: 'canEditProject', title: '案件一括変更' },
        component: () => import('../pages/project/PageProjectBulk.vue')
    },
    {
        // A31 [レポート]着地予測
        path: '/report/forecast',
        name: 'ReportForecast',
        component: () => import('../pages/report/PageForecast.vue')
    },
    {
        // A51 [一覧]顧客一覧
        path: '/customer',
        name: 'Customer',
        component: () => import('../pages/customer/PageCustomer.vue')
    },
    {
        // A32 [レポート]追客
        path: '/report/follow-up',
        name: 'ReportFollowUp',
        component: () => import('../pages/report/PageFollowUp.vue')
    },
    {
        // A34 [レポート]月別優先度別営業報告件数
        path: '/report/sales-report',
        name: 'ReportSaleReport',
        component: () => import('../pages/report/PageSaleReport.vue')
    },
    {
        // A35 [レポート]アポまでの日数
        path: '/report/appointment',
        name: 'ReportAppointment',
        component: () => import('../pages/report/PageAppointment.vue')
    },
    {
        // A36 [レポート]受注済み一覧
        path: '/report/order',
        name: 'ReportOrder',
        component: () => import('../pages/report/PageOrder.vue')
    },
    {
        // A37 [レポート]反響実績
        path: '/report/response',
        name: 'ReportResponse',
        component: () => import('../pages/report/PageResponse.vue')
    },
    {
        // A38 [レポート]ステータス別案件数
        path: '/report/status',
        name: 'ReportStatus',
        component: () => import('../pages/report/PageStatus.vue')
    },
    {
        // A39 [レポート]週次活動実績
        path: '/report/weekly-activity',
        name: 'ReportWeeklyActivity',
        component: () => import('../pages/report/PageWeeklyActivity.vue')
    },
    {
        // A3A [レポート]日時架電実績
        path: '/report/call',
        name: 'ReportCall',
        component: () => import('../pages/report/PageCall.vue')
    },
    {
        // A3B [レポート]アポ率・受注率
        path: '/report/rate',
        name: 'ReportRate',
        component: () => import('../pages/report/PageRate.vue')
    },
    {
        // A3C [レポート]競合
        path: '/report/competitor',
        name: 'PageCompetitorReport',
        component: () => import('../pages/report/PageCompetitorReport.vue')
    },
    {
        // A3D [レポート]アポ転換率
        path: '/report/appointment-rate',
        name: 'ReportAppointmentRate',
        component: () => import('../pages/report/PageAppointmentRate.vue')
    },
    {
        // A3E [レポート]指標推移
        path: '/report/indicator',
        name: 'ReportIndicator',
        component: () => import('../pages/report/PageIndicator.vue')
    },
    {
        // A3F [レポート]反響別推移
        path: '/report/transition',
        name: 'ReportTransition',
        component: () => import('../pages/report/PageTransition.vue')
    },
    {
        // A3G [レポート]お客様の声
        path: '/report/voice',
        name: 'ReportVoice',
        component: () => import('../pages/report/PageVoice.vue')
    },
    {
        // A3H [レポート]受注率
        path: '/report/contract',
        name: 'ReportContract',
        component: () => import('../pages/report/PageContract.vue')
    },
    {
        // A40 [マスタ管理]事業部マスタ
        path: '/master/department',
        name: 'MasterDepartment',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageDepartment.vue')
    },
    {
        // A41 [マスタ管理]優先度マスタ
        path: '/master/probability',
        name: 'MasterProbability',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageProbability.vue')
    },
    {
        // A42 [マスタ管理]確実度マスタ
        path: '/master/certainty',
        name: 'MasterCertainty',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageCertainty.vue')
    },
    {
        // A43 [マスタ管理]サブ評価マスタ
        path: '/master/detailed-rank',
        name: 'MasterDetailedRank',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageDetailedRank.vue')
    },
    {
        // A45 [マスタ管理]エリアマスタ
        path: '/master/area',
        name: 'MasterArea',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageArea.vue')
    },
    {
        // A46 [マスタ管理]チャネルマスタ
        path: '/master/channel',
        name: 'MasterChannel',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageChannel.vue')
    },
    {
        // A47 [マスタ管理]アポ取得リストマスタ
        path: '/master/appointment-source',
        name: 'MasterAppointmentSource',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageAppointmentSource.vue')
    },
    {
        // A48 [マスタ管理]競合マスタ
        path: '/master/competitor',
        name: 'MasterCompetitor',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageCompetitor.vue')
    },
    {
        // A4A [マスタ管理]送信元ページマスタ
        path: '/master/detailed-channel',
        name: 'MasterDetailedChannel',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageDetailedChannel.vue')
    },
    {
        // A4B [マスタ管理]資料マスタ
        path: '/master/document',
        name: 'MasterDocument',
        meta: { gate: 'canEditMaster' },
        component: () => import('../pages/master/PageDocument.vue')
    },
    {
        // A61 [システム管理]ユーザ管理
        path: '/system/user',
        name: 'SystemUser',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/system/PageSystemUser.vue')
    },
    {
        // A62 [システム管理]Webサイト管理
        path: '/system/website',
        name: 'SystemWebsite',
        meta: { gate: 'canManageUser' },
        component: () => import('../pages/system/PageSystemWebsite.vue')
    },
    {
        // A63 [システム管理]スプレッドシート同期
        path: '/system/spreadsheet',
        name: 'SystemSpreadsheet',
        meta: { gate: 'canResyncSpreadsheet' },
        component: () => import('../pages/system/PageSystemSpreadsheet.vue')
    },
    {
        // A71 アカウント
        path: '/account/info',
        name: 'AccountInfo',
        component: () => import('../pages/account/PageAccountInfo.vue')
    },
    {
        // A72 Gmail連携
        path: '/account/gmail',
        name: 'AccountGmail',
        meta: { gate: 'canEditProject' },
        component: () => import('../pages/account/PageAccountGmail.vue')
    },

    {
        // トーク画面サンプル
        path: '/talker',
        name: 'Talker',
        component: () => import('../pages/PageTalker.vue')
    },

    /**
     * エラー系
     */
    {
        //403ページ
        path: '/error/403',
        name: 'Error403',
        component: () => import('../pages/error/PageError403.vue')
    },
    {
        //404ページ ＝ 上記のいずれにもマッチしないもの
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: () => import('../pages/error/PageError404.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.every(record => record.meta.for_guest)) {
        //ログインの不要な画面
        next();
    } else {
        //ログインの必要な画面
        if (to.matched.some((record) => !record.meta.for_guest) && !store.state.auth.access_token) {
            //ログイン切れ
            next({ path: '/signin', query: { redirect: to.fullPath } });
        } else {
            //権限なし
            for (let record of to.matched) {
                if (record.meta && record.meta.gate) {
                    if (false === store.getters['auth/' + record.meta.gate]) {
                        next({ path: '/error/403' });
                    }
                }
            }
            next();
        }
    }
});

export default router
